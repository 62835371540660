@import '~utils/variables';

.wrapper {
  background-color: white;
  padding-bottom: 10px;

  .header {
    display: flex;
    justify-content: space-between;
    height: 394px;

    .text {
      margin-bottom: 20px;
      font-size: 14px;
    }

    .textGray {
      color: #707070;
    }

    .socialHandles {
      display: flex;

      span {
        cursor: pointer;
      }

      a {
        text-decoration: none;
        margin-right: 10px;

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .socialHandles.buttons {
      span {
        cursor: pointer;
        margin-right: 10px;
      }

      a {
        width: 160px;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        margin-right: 10px;
        margin-bottom: 1em;

        button {
          width: 100%;
          height: 35px;
          padding-inline: 5px;
          color: white;
          border-radius: 5px;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-right: 0.2em;
          }
        }
      }

      .btnTele {
        margin-right: 1em;
        background-color: $themePurple;
      }

      .btnWhatsapp {
        background-color: #25d366;
      }
    }
  }

  .right {
    align-items: flex-start;
    justify-items: center;
    display: flex;
    flex-direction: column;
    color: #0f0f0f;

    .text {
      text-align: center;
    }
  }

  .main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 33px;

    .col {
      display: flex;
      flex-direction: column;
      color: #707070;

      div {
        margin-bottom: 5px;
      }

      .right {
        .socialHandles {
          span {
            cursor: pointer;
            margin-right: 20px;
            /* Adjust the margin value as needed */
          }
        }
      }
    }

    .logo {
      cursor: pointer;

      img {
        width: 150px;
        margin-bottom: 60px;
      }
    }

    .linksWrapper {
      width: 60%; // Adjusted width
      display: flex;
      justify-content: space-between;
      gap: 20px; // Adjusted gap

      .links {
        .linkhead {
          color: #0f0f0f;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 20px;
        }

        .link {
          color: #707070;
          text-decoration: none;
          padding-block: 3px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .hr {
    margin-block: 3em;
    width: 100%;
  }

  footer {
    section {
      margin-bottom: 2em;

      .title {
        font-weight: 500;
        margin-bottom: 0.5em;
        font-size: 1rem;
      }

      p {
        color: #707070;
        line-height: 30px;
        font-size: 0.9rem;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    .header {
      flex-direction: column;
      align-items: center;
    }

    .socialHandles {
      justify-content: center;
      margin-bottom: 2em;
    }

    .socialHandles.buttons {
      flex-direction: column;
      align-items: center;
    }

    button {
      margin: 0 !important;
      margin-right: 0 !important;
    }

    .text {
      text-align: center;
    }

    .linksWrapper {
      width: 70%;
      display: flex;
      justify-content: space-between;

      .links {
        .link {
          color: #707070;
          text-decoration: none;
          padding-block: 3px;
          margin-bottom: 10px;
        }
      }
    }

    .left {
      margin-bottom: 1em;
      margin-right: 0 !important;
    }

    .right {
      align-items: center;
      justify-items: center;
      display: flex;
      flex-direction: column;

      .text {
        text-align: center;
      }
    }

    .main {
      flex-direction: column;

      .logo {
        order: 2;
        margin-top: 30px;

        div {
          text-align: center;
        }
      }

      .linksWrapper {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 610px) {
  .wrapper {
    .main {
      .linksWrapper {
        align-items: center;
        flex-direction: column;

        .col {
          width: 300px;
          text-align: center;
          margin-bottom: 15px;
        }
      }
    }
  }
}
