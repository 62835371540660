@import '../../utils/variables.scss';

.wrapper {
  padding-block: 50px;
  background-color: #f8f9fa;
  overflow-y: hidden;

  .main {
    display: flex;
    min-height: 100vh;
    width: 100%;
    padding: 100px;
  }

  .left {
    width: 50%;
    padding-right: 2em;

    .title {
      color: black;
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 1em;
      text-align: start;
      margin-right: 30px;
      display: flex;
      align-items: center;
    }

    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      margin-left: 45px;
      color: #636d79;

      span {
        text-align: start;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: start;
        cursor: pointer;
        text-align: start;
        padding: 10px;
        transition: background-color 0.3s;
        width: 100%;

        &:hover {
          background-color: #f0f0f0;
        }

        .line {
          display: none;
        }

        &.active {
          color: #4b9bee;
          position: relative;
          margin-right: 117px;

          span {
            display: flex;
            align-items: center;
          }

          .line {
            // width: 30px;
            // height: px;
            display: inline;
            padding-right: 10px;
          }
        }
      }
    }

    .info {
      padding-top: 50px;
      display: flow-root;
      align-items: flex-start;
      flex-direction: column;

      .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 0.5em;
        text-align: start;
      }

      .details {
        color: #a2a9b0;

        span {
          color: #4b9bee;
        }
      }
    }

    .btnContainer {
      justify-content: start;
      padding-top: 20px;
      text-align: center;
    }
  }

  .right {
    width: 50%;

    .search_bar {
      display: flex;
      align-items: center;
      margin-top: 30px;
      padding: 1em;
      background-color: #feffff;
      border-radius: 5px;
      width: 100%;
      height: 54px;
      position: relative;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-left: 10px;

      &:focus-within {
        border: 1px solid black;
        box-shadow: 1px 1px 7px rgba(1, 1, 1, 0.2);
      }

      input {
        border: none;
        background-color: #feffff;
        outline: none;
        height: 100%;
        width: calc(100% - 40px);
        padding: 0 12px;
        border-radius: 5px;

        &::placeholder {
          color: rgba(1, 1, 1, 0.3);
          margin-bottom: 2px;
        }

        margin: 10px;
      }

      .search_icon {
        position: absolute;
        right: 12px;
        cursor: pointer;
      }
    }
  }

  .faqs {
    width: 100%;
    margin: 10px;
    gap: 50px;

    .faq {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 10px;
      padding: 16px;
      background-color: white;
      width: 100% !important;
      color: #636d79;
      margin-top: 25px;

      div:first-child {
        width: 100%;
        position: relative;
        padding-right: 90px;
        cursor: pointer;

        span {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          color: $themePurple;
        }
      }

      div:last-child {
        margin-top: 1em;
        color: #a2a9b0;
        line-height: 25px;
        height: 0;
        display: none;
      }
    }

    .faq.open {
      height: auto;

      div:last-child {
        height: auto;
        display: block;
      }
    }
  }

  .btnContainer {
    display: flex;
    justify-content: center;

    button {
      background-color: $themePurple;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 1138px) {
    .main {
      flex-direction: column;
    }

    .left,
    .right {
      width: 100%;
      padding: 0;
    }
  }

  @media screen and (max-width: 600px) {
    .main {
      padding: 30px;
    }

    .left,
    .right {
      padding: 0;
    }
  }
}
