@import '~utils/variables';

.blackBackground {
  background: #000000;
  color: #fff;
}

.container {
  align-items: center;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.heading {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
}

form {
  margin-bottom: 20px;

  input {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 5px;
    outline: none;
    border: 1px solid #ddd;
    width: 100%;
    max-width: 300px;
  }

  button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 400;

    &:hover {
      background-color: #0056b3;
    }
  }
}

.explanation {
  font-size: 16px;
  margin-bottom: 20px;
  color: #5a7184;
}

.swipergroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }

  .maincard {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: #f8f9fa;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    max-width: 400px;
    margin: 0 auto 40px auto;

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    .cardnamediv {
      padding: 10px 20px;
    }

    h3 {
      font-size: 20px;
      margin-bottom: 10px;
      margin-top: 10px;
      color: #000;
      font-weight: 500;
    }

    p {
      font-size: 16px;
      color: #5a7184;
    }

    a {
      color: #007bff;
      text-decoration: none;
      font-weight: 500;
      transition: color 0.3s ease;

      &:hover {
        color: #0056b3;
      }
    }
  }
}
