.cookie-consent-overlay {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1000;
  animation: slideUp 0.3s ease-out;
  max-width: 400px;
  width: 100%;
}

.cookie-consent-popup {
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 20px;
  position: relative;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.cookie-consent-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cookie-consent-text {
  color: #4a5568;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}

.cookie-consent-link {
  color: #2563eb;
  text-decoration: underline;
}

.cookie-consent-link:hover {
  color: #1d4ed8;
}

.cookie-consent-buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

.cookie-consent-button {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s ease;
}

.cookie-consent-button-deny {
  background: transparent;
  border-color: #d1d5db;
  color: #4b5563;
}

.cookie-consent-button-deny:hover {
  background: #f3f4f6;
  border-color: #9ca3af;
}

.cookie-consent-button-accept {
  background: #2563eb;
  color: white;
}

.cookie-consent-button-accept:hover {
  background: #1d4ed8;
}

.cookie-consent-close {
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  border: none;
  color: #9ca3af;
  cursor: pointer;
  padding: 8px;
  line-height: 1;
  z-index: 2;
}

.cookie-consent-close:hover {
  color: #4b5563;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Mobile styles */
@media (max-width: 640px) {
  .cookie-consent-overlay {
    bottom: 0;
    right: 0;
    left: 0;
    max-width: none;
  }

  .cookie-consent-popup {
    border-radius: 0;
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  .cookie-consent-buttons {
    flex-direction: row;
    justify-content: flex-end;
  }
}
