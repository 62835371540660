@import '../../utils/variables.scss';

.wrapper {
  background-color: $themePurple;
  color: white;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: #e6e6e6;
    color: #b5b5b5;
    border: none;
  }
}
