@import '../../utils/variables.scss';

.wrapper {
  * {
    user-select: none;
  }

  a {
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
  }

  display: flex;
  align-items: center;
  height: $navbarHeight;
  width: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0px 1px 8px rgba(1, 1, 1, 0.1);
  .logo {
    width: 180px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    img {
      width: 150px;
    }
  }
  .desktopWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    .links {
      display: flex;
      justify-content: center;
      height: 100%;
      margin-left: auto;
      gap: 30px;

      .link {
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 500;
        text-decoration: none;
        font-size: 14px;
        margin: auto 0;
        margin-right: 17px;
        height: 40px;
        .top {
          .activeLink {
            color: #000 !important;
          }
        }

        :hover {
          color: #070707;
        }

        &:last-child {
          margin-right: 0px;
        }

        position: relative;
        .bottom {
          position: absolute;
          display: none;
          top: 2.5em;
          right: 50%;
          transform: translateX(50%);
          width: 200px;
          border-radius: 5px;
          background-color: white;
          box-shadow: 1px 1px 15px rgba(1, 1, 1, 0.1);
          padding: 5px;
          min-height: 50px;
          .sublink {
            cursor: pointer;
            margin-bottom: 5px;
            padding: 5px 0;
            text-align: center;
            color: rgba(1, 1, 1, 0.7);
            display: block;
          }
          div:last-child {
            margin-bottom: 0;
          }

          div:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid white;
            top: -5px;
            right: 50%;
            transform: translateX(50%);
          }
        }

        span {
          display: inline-block;
          cursor: pointer;
          padding: 5px 2px;
        }

        span:last-child {
          margin-left: 0px;
          width: 16px;
          svg {
            position: relative;
            top: 3.5px;
            color: #7d7d7d;
          }
        }
      }
      .links:hover {
        color: black;
      }

      .bottom.open {
        display: block;
      }

      .link:last-child {
        margin-right: 0;
      }
    }
  }
  .mobileWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    .hamWrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        color: #25d366;
        margin-right: 0.5em;
      }
    }

    .links {
      position: absolute;
      top: calc(#{$navbarHeight} - 21px);
      background-color: white;
      width: 100%;
      box-shadow: 1px 1px 15px rgba(1, 1, 1, 0.1);
      transition: all 0.3s;
      padding: 20px;
      opacity: 0;
      visibility: hidden;
      border-radius: 5px;
      height: auto;
      overflow-y: auto;
      .buttons {
        margin-top: 25px;
      }

      .buttonDashboard {
        margin-top: 40px;
        button {
          width: 150px;
        }
      }

      .link {
        margin-bottom: 15px;
        .sublinks {
          padding-left: 20px;
          .sublink {
            display: block;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            margin-bottom: 0px;
            padding: 5px 0;
          }
        }
      }

      .link:last-child {
        margin-bottom: 0;
      }
    }

    .links.open {
      opacity: 1;
      visibility: visible;
    }
  }
  .buttonDashboard {
    margin-left: auto;
    button {
      background-color: $themePurple !important;
      color: white !important;
      height: 35px;
      width: 120px;
      border-radius: 2px;
      font-weight: 500;
    }
  }
  .buttons {
    margin-left: 30px;
    button {
      width: 95px;
      height: 35px;
      border-radius: 5px;
      color: #070707;
      font-weight: 400;
      transition:
        background-color 0.3s,
        color 0.3s,
        box-shadow 0.3s;
    }

    button:first-child {
      background-image: linear-gradient(to right, #3d92ed, #3d92ed);
      color: white;
      margin-right: 10px;
    }

    button:first-child:hover {
      background-image: linear-gradient(to right, #000, #000);
      color: rgba(61, 146, 237, 0.8);
    }

    button:last-child:hover {
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }

    button:last-child {
      box-shadow: 0 1px 10px 0 rgba(1, 1, 1, 0.1);
      background-color: rgba(0, 0, 0, 1);
      color: white;
      width: 60px;
    }
  }
}
