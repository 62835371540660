.wrapper {
  padding: 0px 100px;
}

@media screen and (max-width: 1250px) {
  .wrapper {
    padding: 0px 50px;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    padding: 0px 40px;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    padding: 0px 10px;
  }
}
