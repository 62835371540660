@import '../../utils/variables.scss';

.PrivateRadioButtonIcon-root-8 {
  color: rgba(224, 227, 230, 1) !important;
}

.wrapper {
  padding-top: calc(#{$navbarHeight});
  width: 100%;
  background-color: #f8f9fa;

  .header {
    background-image: linear-gradient(to left, #121212, #19191a);
    height: 3em;
    padding-inline: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.8rem;
    font-weight: 600;
  }

  .main {
    display: flex;

    .textarea {
      margin-top: 15px;
      width: 50%;
      border-color: 'green';
      border-width: 2;
    }

    .left {
      width: 100% !important;
      padding-top: 50px;
      padding-right: 2em;
      padding-left: 150px;
      margin-top: 20px;

      .alreadyRegistered {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 10px;
        color: #7a86a1;

        span {
          cursor: pointer;
          margin-left: 5px;
          color: #4b9bee;
        }
      }

      .welcome {
        width: 100%;

        .title {
          font-size: 50px;
          font-weight: 550;
          color: rgba(51, 51, 51, 1);

          @media screen and (max-width: 1500px) {
            font-size: 40px;
          }

          @media screen and (max-width: 650px) {
            font-size: 28px;
          }

          @media screen and (max-width: 300px) {
            font-size: 25px;
          }
        }

        .askquestion {
          // padding: 10px;
          top: 0;
          right: 0;
          color: #7a86a1;

          span {
            cursor: pointer;
            color: #4b9bee;
          }
        }
      }

      .logo {
        display: flex;
        flex-direction: column;
        align-items: center;

        .image {
          margin-bottom: 10px;

          img {
            width: 130px;
          }
        }
      }

      .error {
        width: 100%;
        color: red;
        font-size: 13px;
      }

      .deptError {
        text-align: center;
        margin-top: -40px !important;
        margin-bottom: 30px;
      }

      .usernameError {
        margin-top: -20px !important;
        margin-bottom: 10px;
        text-align: left;
      }

      .category {
        font-size: 0.9rem;
        margin-bottom: 0.5em;
        display: inline-block;
        color: rgba(40, 54, 70, 1);
        font-weight: 550;
      }

      .selectors {
        // margin: 30px 0 50px 0;
        display: flex;
        // flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;

        .selectorPair {
          display: flex;
          width: 100%;
          margin-bottom: 1px;

          &:last-child {
            .selector:last-child {
              margin-right: 0;
            }
          }
        }

        .selector {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          // position: relative;
          .name {
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            margin-top: 5px;
            text-align: center;
            margin-bottom: 5px;
            cursor: pointer;
            font-weight: 400;
          }

          .checkbox {
            width: 50px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 10px;
            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      form {
        width: 100%;

        * {
          user-select: none;
        }

        .category {
          width: 70%;
        }

        .message {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          margin-bottom: 20px;

          .textarea {
            margin-top: 15px;
            width: 100%;
            resize: none;
            border: 2px solid rgba(236, 238, 240, 1);
            outline: none;
            padding: 12px;
          }

          .textarea::placeholder {
            padding-top: 10px;
            padding-left: 10px;
          }

          label {
            font-size: 0.9rem;
            font-weight: 550;
            color: rgba(40, 54, 70, 1);
          }
        }

        .errors {
          margin-top: -10px;
          color: red;
          font-size: 13px;
          margin-bottom: 15px;
          padding-left: 4px;
        }

        .textGroup {
          width: 100%;
          display: flex;
          gap: 30px;
          margin-top: 30px;

          @media screen and (max-width: 650px) {
            flex-direction: column !important;
          }
        }

        .agreeText {
          color: #575757;
          font-size: 14px;
          margin-right: 20px;
          margin-bottom: 30px;

          span {
            color: #4b9bee;
          }
        }

        .row {
          width: 100%;
          position: relative;
          margin-bottom: 30px;

          svg {
            position: absolute;
            right: 15px;
            // top: 15x;
            bottom: 30px;
            width: 17px;
            color: $themePurple;
            cursor: pointer;
          }

          label {
            font-size: 0.9rem;
            margin-bottom: 0.5em;
            display: inline-block;
            font-weight: 550;
            color: rgba(40, 54, 70, 1);
          }

          .firstName {
            margin-right: 10px;
          }

          .textField {
            flex: 1;
            margin-right: 30px;

            &:last-child {
              margin-right: 0;
            }

            input {
              width: 100%;
              height: 50px;
              border: 2px solid rgba(236, 238, 240, 1);
              padding-left: 15px;
              color: rgba(153, 153, 153, 1);
            }
          }
        }

        .checkboxContainer {
          margin: 20px 0;
        }

        .btnContainer {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          div {
            color: #575757;
            font-size: 14px;
            margin-right: 20px;

            span {
              border-bottom: 1px solid #575757;
              color: $themePurple;
              font-weight: 500;
              cursor: pointer;
            }
          }
        }
      }
    }

    .right {
      width: 60%;

      // padding: 10px;
      img {
        margin-bottom: 8px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 2180px) {
  .wrapper {
    .main {
      .left {
        width: 50%;
        padding-right: 5em;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .wrapper {
    .main {
      .textField {
        width: 100%;
      }

      .right {
        width: 80%;

        img {
          width: 100%;
        }
      }

      .left {
        width: 60%;
      }

      .textGroup {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .wrapper {
    .main {
      flex-direction: column;

      .textField {
        width: 100%;
      }

      .left,
      .right {
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
      }

      .right {
        padding-top: 50px;
      }

      .left {
        padding-top: 100px;
        width: 100% !important;
        padding-inline: 3em;
        max-width: unset;

        .selectors {
          width: 100%;

          .selector {
            margin-right: 25px;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .wrapper {
    padding: 0;

    .main {
      padding-bottom: 3em;

      .textField {
        width: 100%;
      }

      .right {
        display: none;
      }

      .left {
        padding-inline: 1em;

        .selectors {
          flex-direction: column;
          align-items: center;

          .selectorPair {
            .selector {
              // min-width: 105px;
              .name {
                padding-top: 0 !important;
              }

              .title {
                padding-top: 10px;
                justify-content: center;
              }
            }

            .selector:last-child {
              margin-right: 0;
            }
          }
        }

        form {
          .row {
            flex-direction: column;
          }
        }
      }
    }
  }
}
